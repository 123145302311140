import React from "react";
import ArticleComponent from "../component/Blog/articleComponent";
import ArticleHomeComponent from "../component/Blog/articleHomeComponent";
import { graphql } from 'gatsby';
import FooterComponent from "../component/footerComponent";
import Layout from '../component/layout'
const SkillsComponent =(props) =>{
    const {
        data:{
            wpPost:{
              seo,
                blogArticle :{
                    blogArticleDescription,
                    blogArticleText,
                    blogArticleEndImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:blogArticleEndImage
                        },
                      },
                    },
                    blogArticleHeadImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:blogArticleHeadImage
                        },
                      },
                    },
                  },
                  blogArticleHomeSection :{
                    blogArticleHomeData,
                    blogArticleHomeDescription,
                    blogArticleHomeTime,
                    blogArticleHomeTitle,
                    blogArticleHomeImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:blogArticleHomeImage
                        },
                      },
                    },
                  },
            },
        },
        pageContext: { title },
    } = props;
    return(
        <Layout title={title} seo={seo}>
        <ArticleHomeComponent
         articleTitle={blogArticleHomeTitle}
         articleText={blogArticleHomeDescription}
         articleData={blogArticleHomeData}
         articleTime={blogArticleHomeTime}
         ArticleImage={blogArticleHomeImage}
        />
        <ArticleComponent
          articleText={blogArticleText}
          articleTextImage={blogArticleHeadImage}
          articleEndImage={blogArticleEndImage}
          articleHeadText={blogArticleDescription}
        />
        <FooterComponent/>
        </Layout>
    )
}
export const query = graphql`
  query {
    wpPost(slug: {eq: "the-5-core-skills-the-future-workforce-needs-to-have"}) {
      seo {
        metaDesc
        title
      }
        blogArticle {
            blogArticleDescription
            blogArticleText
            blogArticleEndImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            blogArticleHeadImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        blogArticleHomeSection {
          blogArticleHomeData
          blogArticleHomeDescription
          blogArticleHomeTime
          blogArticleHomeTitle
          blogArticleHomeImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
  }
  `;
export default SkillsComponent